import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";



import googleAds from "../images/google-ads.png";
import googleAnalytics from "../images/google-analytics-logo.png";
import optimizely from "../images/optimizely-logo.png";
import pardot from "../images/pardot-logo.png";
import hubspot from "../images/hubspot-logo.png";
import salesforce from "../images/salesforce-logo.png"
import emailSendIcon from "../images/paper-plane-solid.svg"


function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="text-left mt-2 md:mb-16">
        <div className="flex">
          <div className="w-1/2"><h1>Cameron<br/>Bisset</h1></div>
        </div>
      </section>

      <section id="services" className="text-left bg-teal text-white my-10 py-8 px-4 rounded shadow-xl">
      <div className="flex">
        <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4"><h2>Services<br/>...</h2>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2"></div>
        <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4">
          <h3 className="pb-2">Consulting & audit</h3>
          <p>If you don't know where to start, we can dive deep into your existing marketing or website and uncover your.</p>
          <h3 className="pt-8 pb-2">Campaign management</h3>
          <p>We can design campaigns that will capture your audience, wherever they are and whoever they are.</p>
          <h3 className="pt-8 pb-2">Web project support</h3>
          <p>Getting a second opinion can help propel an existing website project towards its conclusion if you don't have the expertise inhouse.</p>
          <h3 className="pt-8 pb-2">Set-up and integration</h3>
          <p>If you know what you need to do, we can help you do it. We can help create a marketing stack that generates and converts opportunities.</p>
          <h3 className="pt-8 pb-2">Training</h3>
          <p>Bring expertise inhouse by training up your team in Google Analytics, Pardot, Hubspot, UX optimisation...</p>
        </div>
      </div>
      </section>
      <section className="text-center my-10">
      <div className="flex items-center flex-wrap">
        <div className="w-1/3 md:w-1/6 lg:w-1/6 xl:w-1/6">
          <img
          src={pardot} className="object-contain h-20 w-full block mx-auto p-2" alt="sample alt text"/>
        </div>
        <div className="w-1/3 md:w-1/6 lg:w-1/6 xl:w-1/6">
          <img
          src={googleAnalytics} className="object-contain h-20 w-full block mx-auto p-2" alt="sample alt text"/>
        </div>
        <div className="w-1/3 md:w-1/6 lg:w-1/6 xl:w-1/6">
          <img
          src={optimizely} className="object-contain h-20 w-full block mx-auto p-2" alt="sample alt text"/>
        </div>
        <div className="w-1/3 md:w-1/6 lg:w-1/6 xl:w-1/6">
          <img
          src={googleAds} className="object-contain h-20 w-full block mx-auto p-2" alt="sample alt text"/>
        </div>
        <div className="w-1/3 md:w-1/6 lg:w-1/6 xl:w-1/6">
          <img
          src={salesforce} className="object-contain h-20 w-full block mx-auto p-2" alt="sample alt text"/>
        </div>
        <div className="w-1/3 md:w-1/6 lg:w-1/6 xl:w-1/6">
          <img
          src={hubspot} className="object-contain h-20 w-full block mx-auto p-2" alt="sample alt text"/>
        </div>
      </div>
      </section>

      <section className="text-left md:text-center lg:text-center xl:text-center bg-yellow my-10 py-8 px-4 rounded shadow-xl">
      <div className="flex p-4 justify-center">
        <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <h2>Experience<br/>...</h2>
        </div>
      </div>

      <div className="flex-wrap md:flex lg:flex xl:flex">
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4"><h3>Marketing automation</h3>
      <p>Automation gives your marketing the chance to punch well above your weight.</p></div>
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4"><h3>Website optimisation</h3>
      <p>Optimisation requires continuious testing and tweaking in response to the behaviour of humans and search engines.</p></div>
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4"><h3>Reporting & analytics</h3>
      <p>We can use your data to power decisions making processes, and engage stakeholders with clear narratives.</p></div>
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4"><h3>Growth marketing</h3>
      <p>Combining automation and creativity, we can find, engage, nurture and convert your audience.</p></div>

      </div>
      </section>

      <section id="contact" className="my-10">
      <div className="flex-shrink justify-between md:flex lg:flex xl:flex items-center">
        <div className="text-center md:text-left lg:text-left xl:text-left w-full md:w-1/4 lg:w-1/4 xl:w-1/4 p-4"><h1>Talk to me</h1></div>
        <div className="text-left md:text-right lg:text-right xl:text-right w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4">


          <form method="post" action="https://formspree.io/hi@cambisset.com" className="mx-auto md:w-1/2 inline">
            <div className="flex">
              <div className="text-left md:text-right lg:text-right xl:text-right w-full md:w-7/8 lg:w-7/8 xl:w-7/8 p-4">
                <input
                  className="outline-none w-1/2 border-b-2 border-lightGrey text-lg md:text-sm lg:text-sm xl:text-sm focus:border-teal"
                  id="name"
                  placeholder="Your name"
                  type="text"
                  name="name"
                />

                <input
                  className="outline-none w-1/2 border-b-2 border-lightGrey text-lg md:text-sm lg:text-sm xl:text-sm focus:border-yellow"
                  type="email"
                  placeholder="Your email"
                  name="_replyto"
                />

                <input
                  className="outline-none w-full text-lg md:text-sm lg:text-sm xl:text-sm border-t-2 border-white focus:border-purple"
                  id="message"
                  placeholder="Your thoughts"
                  type="text"
                  name="message"
                />


                <input type="text" name="_gotcha" className="hidden" />
              </div>
              <div className="flex">
                <button className="align-bottom">
                  <img
                src={emailSendIcon} className="h-6 mx-auto" alt="sample alt text"/>
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
      </section>

      <section id="resources" className="text-left bg-transparent my-10">
      <div className="flex flex-wrap">
      <div className="bg-transparent w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pr-2 lg:pr-2 xl:pr-2">
        <div className="bg-purple text-white shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48">
          <h2>Resources<br/>...</h2>
          <p>Every now and then we're compelled to create guides for training or articles to get a point across.</p>
        </div>
      </div>
        <div className="bg-transparent text-black w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pl-2 lg:pl-2 xl:pl-2">
          <a href="https://medium.com/@CameronBisset/the-complete-google-analytics-glossary-9961270cb200" target="_blank" rel="noopener noreferrer">
          <div className="bg-lightPurple shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48">
            <h3>Google Analytics Glossary</h3><p>Ever wondered what the difference is between a metric and a dimension? How about between a hit, pageview or session?</p><p class="leading-8"><span className="text-purple font-medium">Medium</span></p>
          </div>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="bg-transparent text-black w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pr-2 lg:pr-2 xl:pr-2">
          <a href="https://trello.com/b/JBgidjLy" target="_blank" rel="noopener noreferrer">
          <div className="bg-lightPurple shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48 overflow-hidden">
            <h3>Overkill Website Launch Checklist</h3><p>Don't allow room for mistakes with this completely overkill launch checklist.</p><p class="leading-8"><span className="text-purple font-medium">Trello</span></p>
          </div>
          </a>
        </div>
        <div className="bg-transparent text-black w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pl-2 lg:pl-2 xl:pl-2">
          <a href="https://trello.com/b/VVFRSdTY" target="_blank" rel="noopener noreferrer">
          <div className="bg-lightPurple shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48">
            <h3>Kanban Board - Template</h3><p>Keep a project on track, or simple streamline your to-do list.</p><p class="leading-8"><span className="text-purple font-medium">Trello</span></p>
          </div>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="bg-transparent text-black w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pr-2 lg:pr-2 xl:pr-2">
          <a href="https://trello.com/b/JBgidjLy" target="_blank" rel="noopener noreferrer">
          <div className="bg-lightPurple shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48 overflow-hidden">
            <h3>Bug Tracking - Template</h3><p>Make sure all bugs are captured and accounted for with this Trello template.</p><p class="leading-8"><span className="text-purple font-medium">Trello</span></p>
          </div>
          </a>
        </div>
        <div className="bg-transparent text-black w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pl-2 lg:pl-2 xl:pl-2">
          <a href="https://medium.com/explanatory-gap/why-you-need-to-shorten-your-website-copy-6034517686f3" target="_blank" rel="noopener noreferrer">
          <div className="bg-lightPurple shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48">
            <h3>Please stop paying for stock images</h3><p>Why anyone would pay for Shutterstock or iStock is beyond me.</p><p class="leading-8"><span className="text-purple font-medium">Medium</span></p>
          </div>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="bg-transparent text-black w-full md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 md:pr-2 lg:pr-2 xl:pr-2">
        <a href="https://medium.com/explanatory-gap/why-you-need-to-shorten-your-website-copy-6034517686f3" target="_blank" rel="noopener noreferrer">
          <div className="bg-lightPurple shadow-xl w-full py-8 px-8 rounded shadow-xl md:h-64 lg:h-64 xl:h-48 overflow-hidden">
          <h3>Why you need to shorten your website copy</h3><p>Professional service firms have a bad reputation when it comes to corporate websites.</p><p class="leading-8"><span className="text-purple font-medium">Medium</span></p>
          </div>
          </a>
        </div>
      </div>
      </section>

    </Layout>
  );
}

export default IndexPage;
